<template>
  <div class="animated fadeIn">
    <div class="row mx-md-n3 mx-0">
      <div class="col-xl-3 col-md-4 col-sm-6 col-12">
        <label>Relatório:</label>
        <model-list-select
          :list="reports"
          option-value="value"
          option-text="label"
          v-model="selectedReport"
          placeholder="Selecione o relatório"
        />
      </div>
    </div>

    <div v-if="selectedReport && selectedReport.filters" class="card mt-3">
      <div class="card-header">Filtrar por</div>
      <div class="card-body">
        <div class="row mx-md-n3 mx-0 align-items-end">

          <div v-if="selectedReport.filters.includes('P')" class="col-xl-4 col-md-6 col-12 mb-3">
            <b-form-group
              label="Data Inicial"
            >
              <b-form-input
                v-model="filters.startDate"
                type="date"
                required/>
            </b-form-group>
          </div>

          <div v-if="selectedReport.filters.includes('P')" class="col-xl-4 col-md-6 col-12 mb-3">
            <b-form-group
              label="Data Final"
            >
              <b-form-input
                v-model="filters.endDate"
                type="date"
                required/>
            </b-form-group>
          </div>

          <div v-for="(fh, key) in getFilterHeaders"
            class="col-xl-4 col-md-6 col-12 mb-3" :key="'filters-' + key">
            <label>{{fh.label}}:</label>
            <multi-list-select
              v-if="fh.isDataOnFrontOnly"
              :ref="key + '-filter-select'"
              :list="fh.options"
              :selectedItems="filters[fh.idsVar]"
              option-value="value"
              :option-text="fh.optionText ? fh.optionText : 'name'"
              placeholder="Digite para pesquisar"
              @select="onFilterSelect(key, $event)"
            ></multi-list-select>
            <multi-list-select
              v-else
              :ref="key + '-filter-select'"
              :list="fh.options"
              option-value="id"
              :option-text="fh.optionText ? fh.optionText : 'name'"
              :selectedItems="filters[fh.idsVar]"
              placeholder="Digite para pesquisar"
              @searchchange="searchFilter(key, $event)"
              :filterPredicate="alwaysTrueFilterPredicate"
              @select="onFilterSelect(key, $event)"
            ></multi-list-select>
          </div>

        </div>
      </div>
    </div>

    <div v-if="selectedReport && selectedReport.sortOptions" class="card mt-3">
      <div class="card-header">Ordenar por</div>
      <div class="card-body">

        <draggable v-model="sortByValues" group="sortOptions">
          <div v-for="(sortBy, index) in sortByValues" class="row py-3 border mx-0 cursor-grab" :key="index"
            :class="{ 'bg-light': index % 2 != 0 }">
            <div class="col">
              {{sortBy.label}}
            </div>
            <div class="col-auto">
              <button type="button" class="btn btn-danger" @click="removeSortField(index)">
                <i class="fa fa-trash"></i> Remover
              </button>
            </div>
          </div>
        </draggable>

        <div class="row mt-3">

          <div class="col">
            <model-list-select
              ref="sort-by-select"
              :list="nonAddedSortByOptions"
              option-value="value"
              option-text="label"
              v-model="selectedSortByField"
              placeholder="Selecione o campo"
            ></model-list-select>
          </div>

          <div class="col-auto">
            <button type="button" class="btn btn-success" @click="addSortByField()" :disabled="!selectedSortByField || !selectedSortByField.value">
              <i class="fa fa-plus"></i> Adicionar
            </button>
          </div>

        </div>

      </div>
    </div>

    <div class="row mx-md-n3 mx-0 justify-content-end">
      <div class="col-auto pr-0">
        <button type="button" class="btn btn-secondary" @click="resetToDefault()" :disabled="generating">Padrão</button>
      </div>
      <div v-if="generating" class="col-auto">
        <animation/>
      </div>
      <div v-else class="col-auto">
        <button type="button" class="btn btn-primary" @click="generateReport" :disabled="!selectedReport || !selectedReport.value">
          Gerar Relatório
        </button>
      </div>
    </div>

    <confirmation-modal
      id="reset-to-default-confirmation-modal"
      msg="Tem certeza de que deseja retornar aos valores padrões?"
      :ok="confirmResetToDefault"
    ></confirmation-modal>

  </div>
</template>

<script>
import { httpClient } from '@/service'
import Animation from '@/components/loaders/animation'
import { LoadingAnimation } from '@/components/loaders'
import { ModelListSelect } from 'vue-search-select'
import { MultiListSelect } from 'vue-search-select'
import shared from '@/shared/shared';
import ConfirmationModal from '@/components/common/confirmation-modal';
import draggable from 'vuedraggable'

const EMPLOYEE_PRODUCTIVITY_SORT_BY_OPTIONS = [
  { value: 'user.name', label: 'Nome do funcionário' },
  { value: 'startDate', label: 'Início do apontamento' },
  { value: 'endDate', label: 'Fim do apontamento' }
];

export default {
  name: 'reports',
  components: { Animation, LoadingAnimation, ModelListSelect, MultiListSelect, ConfirmationModal, draggable },

  data() {
    return {
      reports: [
        {
          value: 'stock-age',
          label: 'Idade do Estoque',
          filters: ['A', 'E', 'F', 'G', 'S', 'M', 'I', 'P'],
          queryType: 'sql',
          sortOptions: [
            { value: 'i.ref', label: 'Reduzido do item' },
            { value: 'sl.name', label: 'Nome do local de estoque' },
            { value: 'l.name', label: 'Nome do endereço' },
            { value: 'il.created_at', label: 'Data do lote' },
            { value: 'il.lot_number', label: 'Número do lote' }
          ]
        },
        {
          value: 'capacity',
          label: 'Capacidade / Disponibilidade',
          filters: ['A', 'E', 'F', 'G', 'S', 'M', 'I', 'P'],
          queryType: 'sql',
          sortOptions: [
            { value: 'i.ref', label: 'Reduzido do item' },
            { value: 'sl.name', label: 'Nome do local de estoque' },
            { value: 'l.name', label: 'Nome do endereço' }
          ]
        },
        {
          value: 'employee-productivity',
          label: 'Produtividade por Funcionário',
          queryType: 'regen',
          filters: ['U', 'P', 'O'],
          sortOptions: EMPLOYEE_PRODUCTIVITY_SORT_BY_OPTIONS
        },
        {
          value: 'counter-employee-productivity',
          label: 'Produtividade de Conferência por Funcionário',
          filters: ['U', 'P', 'O'],
          queryType: 'regen',
          sortOptions: EMPLOYEE_PRODUCTIVITY_SORT_BY_OPTIONS
        }
      ],
      selectedReport: {},
      generating: false,
      filters: {
        stockLocaleIds: [],
        locationIds: [],
        familyIds: [],
        groupIds: [],
        subGroupIds: [],
        brandIds: [],
        itemIds: [],
        userIds: [],
        orderOperations: [],
        startDate: '',
        endDate: ''
      },
      filtersHeaders: {
        warehouses: { type: 'A', label: 'Armazéns', options: [], idsVar: 'stockLocaleIds', url: 'stock-locales' },
        locations: { type: 'E', label: 'Endereços', options: [], idsVar: 'locationIds' },
        families: { type: 'F', label: 'Familias', options: [], idsVar: 'familyIds' },
        groups: { type: 'G', label: 'Grupos', options: [], idsVar: 'groupIds' },
        subGroups: { type: 'S', label: 'Sub-Grupos', options: [], idsVar: 'subGroupIds', url: 'sub-groups' },
        brands: { type: 'M', label: 'Marcas', options: [], idsVar: 'brandIds' },
        items: { type: 'I', label: 'Itens', options: [], idsVar: 'itemIds', optionText: 'label' },
        users: { type: 'U', label: 'Usuários', options: [], idsVar: 'userIds', optionText: 'label' },
        operations: { type: 'O', label: 'Operações', options: [
          { label: 'Entrada', value: 'ENT' },
          { label: 'Saída', value: 'SAI' },
          { label: 'Realocação', value: 'RAL' },
          { label: 'Transferência', value: 'TRANSFERENCIA' },
          { label: 'Transformação', value: 'TRANSFORMACAO' },
          { label: 'Produção', value: 'PRODUCAO' },
          { label: 'Ajuste', value: 'AJU' },
        ], idsVar: 'orderOperations', optionText: 'label', isDataOnFrontOnly: true }
      },
      loading: false,
      selectedSortByField: {}
    }
  },

  mounted() {
    this.$store.dispatch('app/changePage', { title:'Gerar relatório', size: 3 });

    let today = new Date();
    let thisMonthMinus3 = new Date();
    thisMonthMinus3.setMonth(thisMonthMinus3.getMonth() - 3);

    this.filters.startDate = thisMonthMinus3.toJSON().substring(0, 10);
    this.filters.endDate = today.toJSON().substring(0, 10);
    this.setSortByDescOptions();
    this.loadSortByValues();
  },

  computed: {
    getFilterHeaders() {
      let fhs = {};

      if (this.selectedReport && this.selectedReport.filters && this.selectedReport.filters.length) {
        for (let key in this.filtersHeaders) {
          if (this.selectedReport.filters.includes(this.filtersHeaders[key].type)) {
            fhs[key] = this.filtersHeaders[key];
          }
        }
      }

      return fhs;
    },

    sortByOptions() {
      return this.selectedReport && this.selectedReport.sortOptions ? this.selectedReport.sortOptions : [];
    },

    sortByValues: {
      get() {
        return this.selectedReport && this.selectedReport.sortValues ? this.selectedReport.sortValues : [];
      },
      set(newValue) {
        if (this.selectedReport) {
          this.selectedReport.sortValues = newValue;
          this.reloadSelectedReport();
        }
      }
    },

    nonAddedSortByOptions() {
      return this.sortByOptions.filter(option => !this.sortByValues.find(value => value.label === option.label));
    }
  },

  methods: {
    generateReport() {
      if (this.selectedReport.filters.includes('P') && (
        !this.filters['startDate'] || !this.filters['startDate'].length || !this.filters['endDate'] || !this.filters['endDate'].length
      )) {
        this.$notify.textError('Por favor informe o período');
        return;
      }

      this.saveSortByValues();
      let filtersBody = {};

      for (let key in this.filters) {
        if (this.filters[key].length) {
          if (key === 'startDate') {
            filtersBody[key] = this.filters[key] + ' 00:00:00'
          } else if (key === 'endDate') {
            filtersBody[key] = this.filters[key] + ' 23:59:59'
          } else if (Array.isArray(this.filters[key]) && this.filters[key][0].id) {
            filtersBody[key] = this.filters[key].map(o => o.id);
          } else if (Array.isArray(this.filters[key]) && this.filters[key][0].value) {
            filtersBody[key] = this.filters[key].map(o => o.value);
          } else {
            filtersBody[key] = this.filters[key];
          }

          if (key.endsWith('Date')) {
            let split = this.filters[key].split('-');

            if (split.length === 3) {
              filtersBody[key + 'Label'] = split[2] + '/' + split[1] + '/' + split[0];
            }
          }
        }
      }

      let body = {
        filters: filtersBody,
        sortBy: this.sortByValues.map(v => v.value)
      };
      console.log(body);
      shared.setLocalStorageString('selected_report', this.selectedReport.value);
      shared.setLocalStorageString('selected_report_body', JSON.stringify(body));
      window.open("/report", "_blank");
    },

    async searchFilter(key, searchText) {
      if (!this.loading && searchText && searchText.length) {
        if (this.filtersHeaders[key].searchTimeout) {
          clearTimeout(this.filtersHeaders[key].searchTimeout);
        }

        this.filtersHeaders[key].searchTimeout = setTimeout(() => {
          this.loading = true;
          let selectElements = this.$refs[key + '-filter-select'];

          selectElements.forEach(se => {
            se.$children.forEach(c => {
              c.openOptions();
            });
          });

          if (!this.filtersHeaders[key].url) {
            this.filtersHeaders[key].url = key;
          }

          let urlPrefix = process.env.VUE_APP_API_URL + this.filtersHeaders[key].url;
          let urlSuffix = `?page=${0}&size=${10}`;

          let condition = {
            conditions: [
              {
                field: 'name',
                conditionalOperator: 'LIKE_START',
                value: searchText
              }
            ]
          };

          switch (key) {
            case 'locations': {
              if (this.filters.stockLocaleIds && this.filters.stockLocaleIds.length) {
                condition.conditions.push({
                  field: 'stockLocale.id',
                  conditionalOperator: 'IN',
                  value: this.filters.stockLocaleIds.map(o => o.id)
                });
              }

              break;
            }
            case 'items': {
              condition = {
                conditions: [
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'description',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: shared.mountReduzidoFilter(searchText)
                      }
                    ]
                  }
                ]
              };

              if (this.filters.familyIds && this.filters.familyIds.length) {
                condition.conditions.push({
                  field: 'family.id',
                  conditionalOperator: 'IN',
                  value: this.filters.familyIds.map(o => o.id)
                });
              }

              if (this.filters.groupIds && this.filters.groupIds.length) {
                condition.conditions.push({
                  field: 'group.id',
                  conditionalOperator: 'IN',
                  value: this.filters.groupIds.map(o => o.id)
                });
              }

              if (this.filters.subGroupIds && this.filters.subGroupIds.length) {
                condition.conditions.push({
                  field: 'subGroup.id',
                  conditionalOperator: 'IN',
                  value: this.filters.subGroupIds.map(o => o.id)
                });
              }

              if (this.filters.brandIds && this.filters.brandIds.length) {
                condition.conditions.push({
                  field: 'brand.id',
                  conditionalOperator: 'IN',
                  value: this.filters.brandIds.map(o => o.id)
                });
              }

              break;
            }
            case 'users': {
              condition = {
                conditions: [
                  {
                    field: 'type',
                    value: 'U'
                  },
                  {
                    logicalOperator: 'OR',
                    conditions: [
                      {
                        field: 'name',
                        conditionalOperator: 'LIKE_START',
                        value: searchText
                      },
                      {
                        field: 'ref',
                        conditionalOperator: 'LIKE_END',
                        value: searchText
                      }
                    ]
                  }
                ]
              };

              break;
            }
          }

          httpClient.post(`${urlPrefix}/condition${urlSuffix}`, condition).then(({ data }) => {
            this.loading = false;
            this.filtersHeaders[key].options = data.data.content;

            this.filters[this.filtersHeaders[key].idsVar].forEach(selectedItem => {
              if (!this.filtersHeaders[key].options.find(o => o.id === selectedItem.id)) {
                this.filtersHeaders[key].options.push(selectedItem);
              }
            });

            switch (key) {
              case 'items': this.setItemsLabel(); break;
              case 'users': this.setUsersLabel(); break;
            }
          })
          .catch((error) => {
            this.loading = false
            if (error.message) {
              this.$notify.error(error)
            }
          });
        }, 500);
      }
    },

    onFilterSelect(key, items) {
      this.filters[this.filtersHeaders[key].idsVar] = items;
    },

    reloadSelectedReport() {
      let scrollPositionKey = 'wms/reports/scrollPosition';
      shared.saveScrollPosition(scrollPositionKey);
      let temp = this.selectedReport;
      this.selectedReport = {};

      setTimeout(() => {
        this.selectedReport = temp;

        this.$nextTick(() => {
          shared.restoreScrollPosition(scrollPositionKey);
        });
      }, 1);
    },

    alwaysTrueFilterPredicate() {
      return true;
    },

    setItemsLabel() {
      if (this.filtersHeaders['items'].options && this.filtersHeaders['items'].options.length) {
        this.filtersHeaders['items'].options.forEach(item => {
          if (item.id) {
            item.label = this.getItemReduzidoFromRef(item.ref) + " - " + item.description;
          }
        });
      }
    },

    setUsersLabel() {
      if (this.filtersHeaders['users'].options && this.filtersHeaders['users'].options.length) {
        this.filtersHeaders['users'].options.forEach(user => {
          user.label = user.ref + ' - ' + user.name;
        });
      }
    },

    getItemReduzidoFromRef(ref) {
      return shared.getItemReduzidoFromRef(ref);
    },

    loadSortByValues() {
      this.setDefaultSortByValues();
      let str = localStorage.getItem(shared.SORT_BY_VALUES_LOCAL_STORAGE_KEY);

      if (str) {
        try {
          let obj = JSON.parse(str);

          if (obj) {
            Object.keys(obj).forEach(key => {
              let sortValues = obj[key];
              let report = this.reports.find(r => r.value === key);

              if (report) {
                report.sortValues = sortValues;
              }
            });
          }
        } catch (e) {
          console.error(e);
          this.$notify.error('Houve um erro ao carregar a ordenação');
        }
      }
    },

    setSortByDescOptions() {
      let directions = [ 'ASC', 'DESC' ];

      loop1:
      for (let report of this.reports) {
        if (report.sortOptions) {
          let temp = [];

          loop2:
          for (let option of report.sortOptions) {
            loop3:
            for (let dir of directions) {
              let newValue;

              if (report.queryType === 'sql') {
                newValue = option.value + ' ' + dir;
              } else if (report.queryType === 'regen') {
                newValue = {
                  direction: dir,
                  value: option.value
                };
              } else {
                break loop1;
              }

              temp.push({
                label: option.label + ' (' + ( dir === directions[0] ? 'Ascendente' : 'Descendente' ) + ')',
                value: newValue,
                dir: dir
              });
            }
          }

          report.sortOptions = temp;
        }
      }
    },

    setDefaultSortByValues() {
      this.reports.forEach(report => {
        if (report.sortOptions) {
          report.sortValues = report.sortOptions.filter(option => option.dir === 'ASC');
        } else {
          report.sortValues = [];
        }
      });
    },

    saveSortByValues() {
      let body = {};

      this.reports.forEach(report => {
        if (report.sortValues) {
          body[report.value] = report.sortValues;
        }
      });

      localStorage.setItem(shared.SORT_BY_VALUES_LOCAL_STORAGE_KEY, JSON.stringify(body));
    },

    resetToDefault() {
      this.$bvModal.show('reset-to-default-confirmation-modal');
    },

    confirmResetToDefault() {
      this.setDefaultSortByValues();
      this.reloadSelectedReport();
    },

    addSortByField() {
      if (!this.selectedSortByField || !this.selectedSortByField.label) {
        this.$notify.textError('Por favor selecione o campo');
        return;
      }

      if (this.sortByValues.find(v => v.label === this.selectedSortByField.label)) {
        this.$notify.textError('Este campo já foi adicionado');
        return;
      }

      this.sortByValues.push(this.selectedSortByField);
      this.selectedSortByField = {};
    },

    removeSortField(index) {
      this.sortByValues.splice(index, 1);
      this.reloadSelectedReport();
    }
  }
}
</script>
